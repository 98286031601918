/**
 * defined in assets/scss/layout/ce-layout.scss
 *
 * @returns {{"100": string, "101": string, "102": string, "103": string, "104": string, "105": string, "106": string, "107": string}}
 * @constructor
 */
export const DefaultLayoutMappings = () => {
  return {
    100: "bg-color-dark",
    101: "bg-highlight-color",
    102: "bg-color-gray",
    103: "border-color-gray",
    104: "top-border-color-gray",
    105: "bottom-border-color-gray",
    106: "top-bottom-border-color-gray",
    107: "bg-color-white",
  };
};
