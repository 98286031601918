import React from "react";
import CeWrapper from "../../global/CeWrapper";
import css from "./Textpic.module.scss";
import SwiperCore, { Navigation, Pagination, Lazy } from "swiper";
import CeDataGetter from "../../../libs/CeDataGetter";
import ReadMoreText from "../../global/ReadMoreText/ReadMoreText";
import ImageGallery from "../../global/ImageGallery/ImageGallery";
import { DefaultLayoutMappings } from "../../../libs/CeDefaults";

SwiperCore.use([Navigation, Pagination, Lazy]);

const Textpic = ({ ceData }) => {
  /**
   * Render images of textpic element
   */
  const RenderImages = ({ className }) => {
    if (!ceData.contentParts || !ceData.contentParts.gallery.items) {
      return null;
    }

    return (
      <ImageGallery
        data={ceData.contentParts.gallery}
        className={className}
        id={CeDataGetter.getUid(ceData)}
      />
    );
  };

  let additionalImageWrapperClass = css.image;
  let additionalBodytextClass = css.image;

  if (ceData.contentParts?.gallery?.items) {
    switch (parseInt(ceData.contentParts.gallery?.imageorient)) {
      case 25:
        // change style for image behind text
        additionalImageWrapperClass = css.imageBehind;
        additionalBodytextClass = css.bodytextImageBehind;
        break;
    }
  }

  /**
   * Check if show more comment exists
   **/
  const hasShowMoreComment = (text) => {
    return text && text.includes("<!--more-->");
  };

  return (
    <CeWrapper
      ceData={ceData}
      renderHeader={true}
      layoutMapping={DefaultLayoutMappings()}
    >
      {/*Image above or behind*/}
      {[0, 1, 2, 17, 18, 25].includes(
        parseInt(ceData.contentParts?.gallery?.imageorient)
      ) && <RenderImages className={additionalImageWrapperClass} />}

      {hasShowMoreComment(ceData.contentParts?.bodytext) ? (
        <ReadMoreText
          text={ceData.contentParts?.bodytext}
          moreLabel={ceData.contentParts?.moreTextLabel}
          lessLabel={ceData.contentParts?.lessTextLabel}
          bodytextClass={css.bodytext}
          id={CeDataGetter.getUid(ceData)}
        />
      ) : (
        <div
          className={`bodytext ${css.bodytext} ${additionalBodytextClass}`}
          dangerouslySetInnerHTML={{ __html: ceData.contentParts?.bodytext }}
        />
      )}

      {/* Image below */}
      {[8, 9, 10].includes(
        parseInt(ceData.contentParts?.gallery?.imageorient)
      ) && <RenderImages className={additionalImageWrapperClass} />}
    </CeWrapper>
  );
};
export default Textpic;
