import React, { useEffect, useState } from "react";
import css from "./ReadMoreText.module.scss";
import Icon from "../../../../../components/Icon";

const ReadMoreText = ({
  id,
  text,
  lessLabel,
  moreLabel,
  bodytextClass,
  showMorePlaceholder = "<!--more-->",
  showMorePlaceholderNodeValue = "more",
}) => {
  let additionalBodytextClass = "";
  lessLabel = lessLabel || "weniger anzeigen";
  moreLabel = moreLabel || "mehr anzeigen";

  if (text.includes(`<p>${showMorePlaceholder}</p>`)) {
    text = text.replace(`<p>${showMorePlaceholder}</p>`, showMorePlaceholder);
  }

  /**
   * Check if show more placeholder exists
   * @param text
   * @returns {*}
   */
  const hasShowMoreComment = (text) => {
    return text && text.includes(showMorePlaceholder);
  };

  /**
   * search "more" placeholder and return placeholder nodes
   **/
  const findMoreComment = (el) => {
    let arr = [];
    for (let i = 0; i < el.childNodes.length; i++) {
      var node = el.childNodes[i];
      if (
        node.nodeType === 8 &&
        node.nodeValue === showMorePlaceholderNodeValue
      ) {
        arr.push(node);
      } else {
        arr.push.apply(arr, findMoreComment(node));
      }
    }
    return arr;
  };

  /**
   * Get height above show more comment to set collapsed height of container
   *
   * @param element
   * @param moreCommentNode
   * @returns {number}
   */
  const getHeightAboveShowMoreComment = (element, moreCommentNode) => {
    // Get coordinates via Range
    const range = document.createRange();
    // range.selectNode(moreCommentNode.previousSibling);
    range.setStartBefore(element.querySelector(".bodytext").firstChild, 0);
    range.setEndAfter(
      moreCommentNode.previousSibling ??
        moreCommentNode.parentNode.previousSibling,
      0
    );

    const coordinates = range.getBoundingClientRect();

    return coordinates.height;
  };

  /**
   * Init collapsed text container
   * @param element
   * @param initialHeight
   */
  const initCollapsedElement = (element, initialHeight) => {
    const bodytext = element.querySelector(".bodytext");

    bodytext.style.maxHeight = initialHeight + "px";
    bodytext.style.overflow = "hidden";
    bodytext.dataset.initialized = true;
  };

  const hasShowMoreCommentInside = hasShowMoreComment(text);

  const [opened, setOpened] = useState(!hasShowMoreCommentInside);

  useEffect(() => {
    // hide elements after <!--more--> placeholder
    if (hasShowMoreCommentInside) {
      additionalBodytextClass += ` ${css.bodytextWithShowMore}`;

      const element = document.getElementById(`read-more-${id}`);
      if (element) {
        const commentNodes = findMoreComment(element);

        if (commentNodes) {
          const bodytext = element.querySelector(".bodytext");

          const heigth = getHeightAboveShowMoreComment(
            element,
            commentNodes[0]
          );

          if (opened) {
            bodytext.style.maxHeight = "9999px";
          } else {
            initCollapsedElement(element, heigth);
          }
        }
      }
    }
  }, [opened]);

  const toggleShowMore = (e) => {
    e.preventDefault();
    setOpened(!opened);
  };

  return (
    <div id={`read-more-${id}`}>
      <div
        className={`bodytext ${bodytextClass} ${additionalBodytextClass}`}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <a className={`show-more ${css.showMore}`} onClick={toggleShowMore}>
        {" "}
        {opened ? lessLabel : moreLabel} <Icon name="expand" size="small" />
      </a>
    </div>
  );
};

export default ReadMoreText;
